import type {
  ReadRegistration,
  Cerfa2069RCI_2024_Payload,
  ReadCerfa2069RCIInputs,
} from "@silexpert/core";
import { CerfaType } from "@silexpert/core";
import cloneDeep from "lodash-es/cloneDeep";
import type { Loadable } from "~/@types/localTypes/utils";

export type AnnexCerfaQueryProperties = {
  itemId: number | null | undefined;
  startDate: string | null;
  endDate: string | null;
  page: number;
  limit: number;
  idCerfaType: number;
};

export type AnnexCerfaDeclaration = Cerfa2069RCI_2024_Payload;

export const defaultAnnexCerfaQueryProperties = ref<AnnexCerfaQueryProperties>({
  itemId: null,
  startDate: null,
  endDate: null,
  page: 1,
  limit: 25,
  idCerfaType: CerfaType.CERFA_2069RCI,
});

const defaultDataForm = {
  mode: "read" as "read" | "creation" | "edition" | "details",
  idExercice: 0,
  declaration: {
    isNil: false,
    isPME: false,
    isParentSociety: false,
    parentSociety: {
      siren: "",
      name: "",
      address: {
        number: "",
        street: "",
        zipCode: "",
        city: "",
        idCountry: 0,
      },
    },
    creditsWithoutRegistration: [],
    creditsWithRegistration: [],
  } as AnnexCerfaDeclaration,
  data: { withoutRegistrationMandatory: [], withRegistration: [] } as ReadCerfa2069RCIInputs,
};

export const useAnnexCerfaStore = defineStore("annexCerfa", {
  state: () => {
    return {
      isLoading: false as boolean,
      queryProperties: cloneDeep(
        defaultAnnexCerfaQueryProperties.value,
      ) as AnnexCerfaQueryProperties,
      items: [] as (ReadRegistration & Loadable)[],
      currentPage: null as number | null,
      maxPages: null as number | null,
      totalItems: null as number | null,
      hasAnyItem: null as boolean | null,
      dataForm: cloneDeep(defaultDataForm) as typeof defaultDataForm,
      controllers: { fetch: null } as { fetch: AbortController | null },
    };
  },
  getters: {},
  actions: {
    reset() {
      this.queryProperties = cloneDeep(defaultAnnexCerfaQueryProperties.value);
      this.items = [];
      this.isLoading = false;
      this.currentPage = null;
      this.maxPages = null;
      this.totalItems = null;
      this.controllers = { fetch: null };
      this.dataForm = cloneDeep(defaultDataForm);
    },
    resetCreation() {
      this.dataForm = cloneDeep(defaultDataForm);
    },
    async fetch() {
      const societyStore = useSocietyStore();
      try {
        const { startDate, endDate, page, limit, idCerfaType } = this.queryProperties;

        const exerciceStore = useExerciceStore();

        const currentExercice = exerciceStore.exercices.find(
          (e) => e.startDate === startDate && e.endDate === endDate,
        );

        if (!currentExercice)
          return $notifier().open({
            type: "error",
            content:
              "Veuillez sélectionner une période correspondant à un exercice entier de la société",
          });

        if (this.controllers.fetch && this.controllers.fetch.signal) {
          await this.controllers.fetch.abort();
          this.controllers.fetch = null;
        }
        this.controllers.fetch = new AbortController();

        this.isLoading = true;

        const response = await $silex().registration.getPaginated(
          societyStore.society!.id!,
          {
            idExercice: currentExercice.id,
            page: page ?? 1,
            limit: limit ?? 25,
            idCerfaType: [idCerfaType],
          },
          this.controllers.fetch.signal,
        );

        this.maxPages = response.maxPages;
        this.currentPage = response.currentPage;
        this.totalItems = response.totalItems;
        this.hasAnyItem = true;
        this.items = response.data.map((d) => ({ ...d, isLoading: false }));
      } catch (error: any) {
        if (error?.code === "ERR_CANCELED" || error?.message === "canceled") return;
        $notifier().open({ type: "error", content: apiErrorToString(error) });
      } finally {
        this.isLoading = false;
      }
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
});
